import gql from 'graphql-tag';

const singlePatternProductCategorizationFragment = gql`
    fragment SinglePatternProductCategorizationFragment on ProductCategorizationNode {
        id
        marketSegment
        application
        niche
        polymer
        definition
        warranty
    }
`;

export default singlePatternProductCategorizationFragment;