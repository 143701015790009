import gql from "graphql-tag";

const singlePatternGeneralFragment = gql`
  fragment SinglePatternGeneralFragment on GeneralNode {
    id
    weight
    width
    gauge
    embossingSelect {
      id
      name
    }
    temperatureReductionTreatment
    topcoat
    constructionType
    printType
    gloss
    piecesNumber
    minPieceNumber
    package
    storage
    storageRollPosition
    polymerCompositionPercentage
    backingCompositionPercentage
    price
  }
`;

export default singlePatternGeneralFragment;
