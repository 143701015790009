import React, {Component} from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import FiltersBox from "scenes/ProductsPage/components/FiltersBar/components/FiltersBox";
import {updateQueryParams} from "services/browser-history";
import ProductList from "scenes/ProductsPage/components/ProductsList";
import {getQueryParamDataAsArray} from "services/browser-history";

class CheckboxFilter extends Component {
  constructor(props) {
    super(props);

    this.handleOnChange = option => this._handleOnChange.bind(this, option)
  }

  /**
   * Handle onChange checkbox input to set new data into queryParams
   * @param option
   * @private
   */
  _handleOnChange(option) {
    const {queryParam} = this.props;

    let nextParams = getQueryParamDataAsArray(queryParam);

    if (nextParams.includes(option.value))
      nextParams = nextParams.filter(value => value !== option.value);
    else
      nextParams.push(option.value);

    updateQueryParams({
      [queryParam]: nextParams,
      ...ProductList.resettled_pagination_query_params(),
    })
  }


  render() {
    const {title, options, queryParam, withoutTranslation} = this.props;
    const currentQueryParamData = getQueryParamDataAsArray(queryParam);

    return (
        <FiltersBox
            title={title}>
          {
            options.map((option, optionIndex) => (
                <div
                    key={`filterBoxOption${optionIndex}`}>
                  <p className="text-capitalize">
                    <input
                        type="checkbox"
                        checked={currentQueryParamData.includes(option.value)}
                        onChange={this.handleOnChange(option)}/>
                    &nbsp;
                    {
                      withoutTranslation ?
                          <span>{option.name}</span>
                          :
                          <FormattedMessage
                              {...option}/>
                    }
                  </p>
                </div>
            ))
          }
        </FiltersBox>
    )
  }
}

CheckboxFilter.defaultProps = {
  withoutTranslation: false
};

CheckboxFilter.propTypes = {
  title: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  queryParam: PropTypes.string.isRequired,
  withoutTranslation: PropTypes.bool
};

export default CheckboxFilter;