import gql from "graphql-tag";
import singlePatternFragment from "utils/fragments/SinglePatternFragment";
import pageInfoFragment from "utils/fragments/PageInfoFragment";

// TODO: hotfix solve filter general_embossing_in using embossing_select_in
const patternsQuery = gql`
  query PatternsQuery(
    $first: Int
    $last: Int
    $afterCursor: String
    $beforeCursor: String
    $orderBy: String
    $search: String
    $productFirst: Int
    $marketSegment: String
    $appearance: String
    $application: String
    $polymer: String
    $backingDescription: ID
    $embossing: String
    $topcoat: ID
    $outdoor: Boolean
    $flameRetardancyTests: [ID]
    $gaugeRange: String
    $widthRange: String
    $weightRange: String
    $isVisibleLibrary: Boolean
  ) {
    patterns(
      first: $first
      last: $last
      after: $afterCursor
      before: $beforeCursor
      orderBy: $orderBy
      name_Icontains: $search
      productcategorization_MarketSegment_In: $marketSegment
      productcategorization_Appearance_In: $appearance
      productcategorization_Application_In: $application
      productcategorization_Polymer_In: $polymer
      productcategorization_Outdoor: $outdoor
      backing_Description_Id: $backingDescription
      #embossing_Select_In: $embossing
      general_Composition_In: $embossing
      general_Topcoat_Id: $topcoat
      successFlameRetardancyTests: $flameRetardancyTests
      gauge_Between: $gaugeRange
      width_Between: $widthRange
      weight_Between: $weightRange
      isVisibleLibrary: $isVisibleLibrary
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...SinglePatternFragment
          imageThumbSmall
          products(first: $productFirst) {
            totalCount
            edges {
              node {
                id
                code
                color
              }
            }
          }
        }
      }
    }
  }
  ${singlePatternFragment}
  ${pageInfoFragment}
`;

export default patternsQuery;
