import gql from 'graphql-tag';

const singlePatternFragment = gql`
    fragment SinglePatternFragment on PatternNode {
        id
        name
        altName
        slug
    }
`;

export default singlePatternFragment;