import gql from 'graphql-tag';

const singlePatternOtherAttributesFragment = gql`
    fragment SinglePatternOtherAttributesFragment on OtherAttributesNode {
        id
        antiStatic 
        antiStaticConductive 
        antiBacterial
        bacterialResistance
        mildewResistance 
        mildewResistanceMethod 
        pinkStainResistance 
        cytotoxicity 
        irritationAndSkinSensitization 
        cleanability
        cleanibilityMethod 
        hydrocarbonResistance
        hydrocarbonResistanceMethod
        oilResistance
        sulfideStaining
        heavyMetals
        phthalateFreeProduct
        hydrolisis
        hydrolisisMethod
        heatBuildup
        heatBuildupMethod
        accousticalAttenuation 
        accousticalAttenuationMethod 
        soundAbsorption
        soundAbsorptionMethod
        foamLamination
        thermoformed
        electricSealable 
        impermeable
        temperatureReductionTreatment
        denimStainResistance
        stainResistanceInHealthcareEnvironment
        ruleMed
    }
`;

export default singlePatternOtherAttributesFragment;