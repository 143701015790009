import React, { Component } from "react";
import ProductHeader from "scenes/ProductDetails/components/ProductHeader";
import ProductSpecs from "scenes/ProductDetails/components/ProductSpecs";
import { Query } from "react-apollo";
import { singlePatternQuery } from "scenes/ProductDetails/queries";
import { Redirect } from "react-router";
import DownloadPatternPDFButton from "scenes/ProductDetails/components/DownloadPatternPDFButton";
import { getParsedQueryParams } from "services/browser-history";
import { QUERY_PARAM_REFERENCE } from "scenes/ProductDetails/constants";
import SendByEmailButton from "scenes/ProductDetails/components/SendByEmailButton";
import { FormattedMessage } from "react-intl";
import "./styles/ProductDetails.scss";
import LoadingMessage from "utils/components/LoadingMessage";
import PatternMarkets from "scenes/ProductDetails/components/PatternMarkets";
import PatternContext from "scenes/ProductDetails/components/ProductSpecs/contexts/PatternContext";
import { Link } from "react-router-dom";

class ProductDetails extends Component {
  render() {
    const { match } = this.props;

    return (
      <Query
        query={singlePatternQuery}
        variables={{
          slug: match.params.productSlug,
        }}
      >
        {({ loading, error, data }) => {
          if ((loading || error) && !data.pattern) return <LoadingMessage />;

          const { pattern } = data;

          if (!pattern) return <Redirect to={`/products`} />;

          // This reference selection must be based on reference query param
          let selectedReference = null;
          const currentReferenceParam =
            getParsedQueryParams()[QUERY_PARAM_REFERENCE];

          if (pattern.products.edges.length > 0) {
            if (currentReferenceParam === undefined)
              selectedReference = pattern.products.edges[0].node;
            else
              selectedReference = pattern.products.edges.find(
                (product) => product.node.color === currentReferenceParam
              ).node;
          }

          return (
            <PatternContext.Provider value={pattern}>
              <div className="product-details">
                <ProductHeader
                  productCategorization={pattern.productcategorization}
                  name={pattern.name}
                  altName={pattern.altName}
                  references={pattern.products.edges}
                  selectedReference={selectedReference}
                />
                <section className="product-details__info">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-8 col-xl-9 order-md-1 mb-5">
                        <ProductSpecs patternId={pattern.id} />
                      </div>
                      <div className="col-12 col-md-4 col-xl-3 order-md-0 mb-5">
                        <div className="product-details-actions">
                          <PatternMarkets sectors={pattern.sectors.edges} />
                          <DownloadPatternPDFButton patternId={pattern.id} />
                          <SendByEmailButton patternId={pattern.id} />
                          <Link
                            to="/documents/warranty"
                            className="btn btn-outline-secondary btn-lg btn-block"
                          >
                            <FormattedMessage
                              id="ProductDetails.warrantyBtnLabel"
                              description="Warranty button label in Product Details component"
                              defaultMessage="Warranty"
                            />
                          </Link>
                          {pattern.maintenanceGuide && (
                            <a
                              href={pattern.maintenanceGuide}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-outline-secondary btn-lg btn-block"
                            >
                              <FormattedMessage
                                id="ProductDetails.maintenanceGuideButton"
                                description="Label of the Maintenance Guide button in Product details page"
                                defaultMessage="Maintenance Guide"
                              />
                            </a>
                          )}
                          {pattern.transformationGuide && (
                            <a
                              href={pattern.transformationGuide}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-outline-secondary btn-lg btn-block"
                            >
                              <FormattedMessage
                                id="ProductDetails.transformationGuideButton"
                                description="Label of the Transformation Guide button in Product details page"
                                defaultMessage="Transformation Guide"
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </PatternContext.Provider>
          );
        }}
      </Query>
    );
  }
}

export default ProductDetails;
