import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./styles/SpecsTableColumn.scss";
import permablok from "utils/components/SpecLogo/images/permablok.svg"
import permablok3 from "utils/components/SpecLogo/images/permablok-3.svg"
import permablokPlus from "utils/components/SpecLogo/images/permablok-plus.svg"
import permablok3Plus from "utils/components/SpecLogo/images/permablok-3-plus.svg"
import permaguard from "utils/components/SpecLogo/images/permaguard.svg"
import silverguard from "utils/components/SpecLogo/images/silverguard.svg"
import sureguard from "utils/components/SpecLogo/images/sureguard.svg"
import pbg from "utils/components/SpecLogo/images/pbg.svg"
import pbg3 from "utils/components/SpecLogo/images/pbg-3.svg"
import hiLoft from "utils/components/SpecLogo/images/hi-loft.svg"
import hiLoftFlex from "utils/components/SpecLogo/images/hi-loft-flex.svg"
import {
  LOGO_HI_LOFT,
  LOGO_HI_LOFT_FLEX,
  LOGO_PBG,
  LOGO_PBG_3,
  LOGO_PERMABLOK,
  LOGO_PERMABLOK_3,
  LOGO_PERMABLOK_PLUS,
  LOGO_PERMABLOK_3_PLUS,
  LOGO_PERMAGUARD,
  LOGO_SILVERGUARD,
  LOGO_SUREGUARD
} from "utils/components/SpecLogo/constants/logos";

const logos = [
  [LOGO_PERMABLOK, permablok],
  [LOGO_PERMABLOK_3, permablok3],
  [LOGO_PERMABLOK_PLUS, permablokPlus],
  [LOGO_PERMABLOK_3_PLUS, permablok3Plus],
  [LOGO_PERMAGUARD, permaguard],
  [LOGO_SILVERGUARD, silverguard],
  [LOGO_SUREGUARD, sureguard],
  [LOGO_PBG, pbg],
  [LOGO_PBG_3, pbg3],
  [LOGO_HI_LOFT, hiLoft],
  [LOGO_HI_LOFT_FLEX, hiLoftFlex],
];

const logoNames = logos.map(logo => logo[0]);
const logoPairs = _.fromPairs(logos);

function getImageByName(name) {
  return logoPairs[name];
}

export default function SpecsTableColumn({value}) {
  if (logoNames.indexOf(value) > -1)
    return <img src={getImageByName(value)} alt={value} className="specs-table-image"/>;

  return <span>{value}</span>;
}

SpecsTableColumn.propTypes = {
  value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node
  ]).isRequired
};
