import React, { Component } from "react";
import "./styles/ComparePage.scss";
import classNames from "classnames/bind";
import { FormattedMessage } from "react-intl";
import proquinalHistory from "services/browser-history";
import withCompareItems from "utils/hoc/withCompareItems";
import SpecsTableAccordion from "utils/components/SpecsTableAccordion";
import { Query } from "react-apollo";
import {
  multiplePatternsQuery,
  multiplePatternsSuccessPdfFlameRetardancyTestsQuery,
} from "scenes/ComparePage/query";
import ProductSpecAccordionBody from "scenes/ProductDetails/components/ProductSpecs/components/ProductSpecsAccordion/components/ProductSpecsAccordionBody";
import { injectIntl } from "react-intl";
import RemoveAllButton from "utils/components/RemoveAllButton";
import CompareCard from "scenes/ProductsPage/components/ProductsList/components/CompareBox/components/CompareCard";
import SpecLogo from "utils/components/SpecLogo";
import PdfResultRow from "../ProductDetails/components/ProductSpecs/components/FlameRetardancyAccordion/components/PdfResultRow";
import {
  SPECS_TABLE_BACKING,
  SPECS_TABLE_FLAME_RETARDANCY,
  SPECS_TABLE_GENERAL,
  SPECS_TABLE_OTHER_ATTRIBUTES,
  SPECS_TABLE_PRODUCT_CATEGORIZATION,
} from "scenes/ProductDetails/components/ProductSpecs/contants/table_names";

export class ComparePage extends Component {
  constructor(props) {
    super(props);

    const { intl } = props;

    this.state = {
      tables: [
        {
          name: intl.formatMessage(SPECS_TABLE_PRODUCT_CATEGORIZATION),
          isOpen: true,
          key: "productcategorization",
        },
        {
          name: intl.formatMessage(SPECS_TABLE_GENERAL),
          isOpen: false,
          key: "general",
        },
        {
          name: intl.formatMessage(SPECS_TABLE_BACKING),
          isOpen: false,
          key: "backing",
        },
        {
          name: intl.formatMessage(SPECS_TABLE_OTHER_ATTRIBUTES),
          isOpen: false,
          key: "otherattributes",
        },
        {
          name: intl.formatMessage(SPECS_TABLE_FLAME_RETARDANCY),
          isOpen: false,
          key: "successPdfFlameRetardancyTests",
        },
      ],
    };

    this.handleGoBackIconClick = this.handleGoBackIconClick.bind(this);
    this.handleTableToggleClick = (tableIndex) =>
      this._handleTableToggleClick.bind(this, tableIndex);
  }

  componentDidUpdate() {
    const { loadingPatterns, patternsToCompare } = this.props;

    if (!loadingPatterns && patternsToCompare.length <= 1)
      this.handleGoBackIconClick();
  }

  handleGoBackIconClick() {
    const { location } = this.props;
    proquinalHistory.push({
      pathname: "/products",
      search: location.search,
    });
  }

  /**
   * Handles clicks on the ProductSpecsAccordion's toggle button to change visibility of the table
   * @param {Number} tableIndex
   * @private
   */
  _handleTableToggleClick(tableIndex) {
    this.setState({
      tables: this.state.tables.map((table, index) => {
        if (index === tableIndex)
          table = {
            ...table,
            isOpen: !table.isOpen,
          };
        return table;
      }),
    });
  }

  render() {
    const { patternsToCompare } = this.props;
    const { tables } = this.state;

    return (
      <div className="compare-page mb-5">
        <div className="container">
          <h1 className="compare-page-title">
            <i
              className="sp-chevron-left"
              onClick={this.handleGoBackIconClick}
            />
            <FormattedMessage
              id="ComparePage.customSearch"
              description="Compare page go-back to Product list page link"
              defaultMessage="Compare products"
            />
          </h1>
          <table
            className={classNames({
              "table-bordered": true,
              "compare-sticky-table": true,
              // .compare-sticky-table--1-product, --2-products...
              [`compare-sticky-table--${patternsToCompare.length}-product${
                patternsToCompare.length > 1 && "s"
              }`]: true,
            })}
          >
            <thead>
              <tr>
                <th>
                  <h5 className="font-weight-bold">
                    <FormattedMessage
                      id="ComparePage.tableTitle"
                      description="Compare page table title"
                      defaultMessage="Compare products"
                    />
                  </h5>
                  <p>
                    <FormattedMessage
                      id="ComparePage.tableCompareAmount"
                      description="Amount of products to compare in Compare page"
                      defaultMessage="You have selected {amount} {productLabel} to compare"
                      values={{
                        amount: patternsToCompare.length,
                        productLabel: `product${
                          patternsToCompare.length > 1 ? "s" : ""
                        }`,
                      }}
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="ComparePage.tableCompareRemoveAllText"
                      description="Compare page table title"
                      defaultMessage="You can remove them to compare others"
                    />
                  </p>
                  <RemoveAllButton />
                </th>
                {patternsToCompare.map((pattern) => (
                  <th key={`Product${pattern.id}`}>
                    <CompareCard pattern={pattern} />
                  </th>
                ))}
              </tr>
            </thead>
          </table>
          <div className="compare-specs-accordions">
            {patternsToCompare.length > 0 && (
              <Query
                query={multiplePatternsQuery}
                variables={{
                  ids: patternsToCompare.map((item) => item.id),
                }}
              >
                {({ loading, error, data }) => {
                  if (loading || error) return null;

                  const { patterns } = data;

                  if (patterns.edges === 0) return null;

                  patterns.edges = patterns.edges.map((item) => {
                    if (
                      item.node.general &&
                      item.node.general.embossingSelect
                    ) {
                      item.node.general.embossing =
                        item.node.general.embossingSelect.name;
                      delete item.node.general.embossingSelect;
                    }
                    if (item.node.general && item.node.general.topcoat)
                      item.node.general.topcoat = (
                        <SpecLogo spec={item.node.general.topcoat} />
                      );

                    if (item.node.backing && item.node.backing.description)
                      item.node.backing.description = (
                        <SpecLogo spec={item.node.backing.description} />
                      );

                    if (
                      item.node.otherattributes &&
                      item.node.otherattributes.antiBacterial
                    )
                      item.node.otherattributes.antiBacterial = (
                        <SpecLogo
                          spec={item.node.otherattributes.antiBacterial}
                        />
                      );
                    return item;
                  });

                  const tableData = ({ key }) =>
                    patterns.edges.map((item) => item.node[key]);

                  return (
                    <>
                      {tables.map((table, tableIndex) => (
                        <SpecsTableAccordion
                          key={`${table.name}-${tableIndex}`}
                          title={table.name}
                          isOpen={table.isOpen}
                          onClickToggle={this.handleTableToggleClick(
                            tableIndex
                          )}
                        >
                          <table className="table table-striped product-specs-table">
                            <tbody>
                              {table.key ===
                              "successPdfFlameRetardancyTests" ? (
                                <Query
                                  query={
                                    multiplePatternsSuccessPdfFlameRetardancyTestsQuery
                                  }
                                  variables={{
                                    ids: patternsToCompare.map(
                                      (item) => item.id
                                    ),
                                  }}
                                >
                                  {({ data }) => {
                                    if (data && data.comparisons) {
                                      const { comparisons } = data;
                                      const comparisonsJson =
                                        JSON.parse(comparisons);

                                      return (
                                        <>
                                          {Object.keys(comparisonsJson).map(
                                            (nameTest) => (
                                              <tr
                                                key={`key_${nameTest}`}
                                                className="specs-table-row specs-table-row--in-compare-page"
                                              >
                                                <th scope="row">{nameTest}</th>
                                                {comparisonsJson[nameTest].map(
                                                  ({
                                                    patternName,
                                                    patternId,
                                                    pdf,
                                                    result,
                                                  }) => (
                                                    <PdfResultRow
                                                      key={`key_${patternName}_${patternId}`}
                                                      pdf={pdf}
                                                      result={result}
                                                      isIndividualPattern={
                                                        false
                                                      }
                                                    />
                                                  )
                                                )}
                                              </tr>
                                            )
                                          )}
                                        </>
                                      );
                                    }
                                    return <></>;
                                  }}
                                </Query>
                              ) : (
                                <>
                                  {console.log(
                                    "tableData(table): ",
                                    tableData({
                                      isOpen: false,
                                      key: "general",
                                      name: "General",
                                    })
                                  )}
                                  {console.log("table: ", table)}
                                  <ProductSpecAccordionBody
                                    withoutTranslation={[
                                      "additionalTests",
                                    ].includes(table.key)}
                                    data={tableData(table)}
                                  />
                                </>
                              )}
                            </tbody>
                          </table>
                        </SpecsTableAccordion>
                      ))}
                    </>
                  );
                }}
              </Query>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withCompareItems(injectIntl(ComparePage));
