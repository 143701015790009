import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { graphql, compose } from "react-apollo";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  singlePatternPublicPDFQuery,
  singlePatternPrivatePDFQuery,
} from "scenes/ProductDetails/components/DownloadPatternPDFButton/queries";
import registerSpecSheetDownloadMutation from "scenes/ProductDetails/components/DownloadPatternPDFButton/mutations";

export const DownloadPatternPDFButton = ({
  singlePatternPrivatePDFQuery,
  singlePatternPublicPDFQuery,
  registerSpecSheetDownloadMutation,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Handle link click to calls registerSpecSheetDownloadMutation mutation
   * @param event {Object}
   */
  function handleLinkClick(event) {
    const { pattern } = singlePatternPrivatePDFQuery;
    registerSpecSheetDownloadMutation({
      variables: {
        input: {
          patternId: pattern.id,
        },
      },
    });
  }

  /**
   * Toggles dropdown's visibility
   * @param event {Object}
   */
  function toggleDropdown(event) {
    setIsOpen(!isOpen);
  }

  if (singlePatternPrivatePDFQuery.error || singlePatternPublicPDFQuery.error)
    return null;

  return (
    <React.Fragment>
      {singlePatternPrivatePDFQuery.loading ||
      singlePatternPublicPDFQuery.loading ? (
        <button disabled className="btn btn-outline-secondary btn-lg btn-block">
          <FormattedMessage
            id="DownloadPatternPDFButton.label"
            description="Product details download pdf button label"
            defaultMessage="Download PDF"
          />
          &nbsp;
          <i className="fa fa-file-pdf-o" />
        </button>
      ) : (
        <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
          {/* Empty color prop prevents reactstrap to add btn class names to the component */}
          <DropdownToggle
            color=""
            className="btn btn-outline-secondary btn-lg btn-block"
          >
            <FormattedMessage
              id="DownloadPatternPDFButton.label"
              description="Product details download pdf button label"
              defaultMessage="Download PDF"
            />
            &nbsp;
            <i className="fa fa-file-pdf-o" />
          </DropdownToggle>
          <DropdownMenu>
            {singlePatternPublicPDFQuery.pattern.pdfUrl && (
              <DropdownItem
                tag="a"
                onClick={handleLinkClick}
                href={singlePatternPublicPDFQuery.pattern.pdfUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="DownloadPatternPDFButton.publicVersion"
                  description="Public version pdf button label"
                  defaultMessage="Public version"
                />
              </DropdownItem>
            )}
            <DropdownItem
              tag="a"
              onClick={handleLinkClick}
              href={singlePatternPrivatePDFQuery.pattern.pdfUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="DownloadPatternPDFButton.privateVersion"
                description="Private version pdf button label"
                defaultMessage="Private version"
              />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
    </React.Fragment>
  );
};

DownloadPatternPDFButton.propTypes = {
  patternId: PropTypes.string.isRequired,
};

export default compose(
  graphql(singlePatternPublicPDFQuery, {
    name: "singlePatternPublicPDFQuery",
    options: (props) => ({
      variables: {
        id: props.patternId,
      },
    }),
  }),
  graphql(singlePatternPrivatePDFQuery, {
    name: "singlePatternPrivatePDFQuery",
    options: (props) => ({
      variables: {
        id: props.patternId,
      },
    }),
  }),
  graphql(registerSpecSheetDownloadMutation, {
    name: "registerSpecSheetDownloadMutation",
  })
)(DownloadPatternPDFButton);
