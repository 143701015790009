// Pagination
export const QUERY_PARAM_FIRST = 'first';
export const QUERY_PARAM_LAST = 'last';
export const QUERY_PARAM_BEFORE_CURSOR = 'before';
export const QUERY_PARAM_AFTER_CURSOR = 'after';

// Search
export const QUERY_PARAM_SEARCH = 'search';

// Filters
export const QUERY_PARAM_SEGMENT = 'segment';
export const QUERY_PARAM_BUSINESS = 'business';
export const QUERY_PARAM_APPEARANCE = 'appearance';
export const QUERY_PARAM_APPLICATION = 'application';
export const QUERY_PARAM_BACKING_TYPE = 'backing-type';
export const QUERY_PARAM_BACKING_DESCRIPTION = 'backing-description';
export const QUERY_PARAM_TOPCOAT = 'topcoat';
export const QUERY_PARAM_POLYMER = 'polymer';
export const QUERY_PARAM_FLAME_RETARDANCY_TEST = 'flame-retardancy-test';
export const QUERY_PARAM_GAUGE_RANGE = 'gauge-range';
export const QUERY_PARAM_WIDTH_RANGE = 'width-range';
export const QUERY_PARAM_WEIGHT_RANGE = 'weight-range';
export const QUERY_PARAM_EMBOSSING = 'embossing';
export const QUERY_PARAM_OUTDOOR = 'outdoor';

export const FILTER_QUERY_PARAMS = [
  QUERY_PARAM_SEGMENT,
  QUERY_PARAM_APPEARANCE,
  QUERY_PARAM_APPLICATION,
  QUERY_PARAM_BACKING_TYPE,
  QUERY_PARAM_BACKING_DESCRIPTION,
  QUERY_PARAM_TOPCOAT,
  QUERY_PARAM_POLYMER,
  QUERY_PARAM_FLAME_RETARDANCY_TEST,
  QUERY_PARAM_GAUGE_RANGE,
  QUERY_PARAM_WIDTH_RANGE,
  QUERY_PARAM_WEIGHT_RANGE,
  QUERY_PARAM_EMBOSSING,
  QUERY_PARAM_OUTDOOR
];