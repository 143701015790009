import _ from "lodash";
import intlMessages from "scenes/ProductDetails/components/ProductSpecs/components/ProductSpecsAccordion/components/ProductSpecsAccordionBody/data/translations-v2";

/**
 * Translates every label of the given two-dimensional array
 * @param {Array} cleanedData
 * @returns {Array}
 */
function translateLabels(cleanedData, intl) {
  return cleanedData.map((item) => {
    const intlMessage = _.get(intlMessages, item[0], null);
    if (intlMessage) item[0] = intl.formatMessage(intlMessage);
    return item;
  });
}

/**
 * Removes unneeded data and returns an array from the given object
 * @param {Object} data
 * @returns {*|Array}
 */
export function cleanData(data, intl) {
  function picker(value, key) {
    return (
      key !== "id" && // id is not needed
      key !== "__typename" && // This is a graphql thing
      value !== null // clean every key without any value
    );
  }

  const cleaned = _.pickBy(data, picker);
  const asArray = _.toPairs(cleaned); // transform into a two-dimensional array [[k, v], [k, v]]
  return translateLabels(asArray, intl);
}
